.main-content {
    animation: fade-in 1s;
    position: relative;
    z-index: 1;
    /* Ensure this is above the footer */
}

@media (min-width: 1024px) {
    .main-content {
        background-color: rgba(18, 18, 18, 1);
    }
}

@media (max-width: 767px) {
    .main-content {
        background-color: black;
    }
}



.footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
    /* Place the footer behind the main content */
    backdrop-filter: blur(10px);
    /* Ensure compatibility with some browsers */
    display: grid;
    grid-template-columns: 1fr;
    /* Default single column for small screens */
}

.radial-background {
    background: radial-gradient(ellipse 45% 55%, rgba(0, 255, 255, 0.03), transparent);
}

@keyframes fade-in {
    from {
        opacity: 0;
    }

    to {
        opacity: 100;
    }
}

/* Ensure footer takes full width on mobile devices */
@media (max-width: 1700px) {
    .footer {
        position: static;
        /* Makes footer part of normal flow in mobile view */
    }
}

.user-info-section {
    margin-top: 0px;
}

@media (min-width: 1700px) {
    .footer {
        grid-template-columns: 1fr 1fr;
        /* Two columns for larger screens */
    }

    .user-info-section {
        margin-top: 5rem;
    }
}

.text-color {
    color: linear-gradient(0deg, #EEF9FF, #EEF9FF),
        radial-gradient(58.24% 134.23% at 33.81% 109.38%, rgba(102, 129, 226, 0.81) 0%, rgba(102, 129, 226, 0) 95.37%)
        /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
        ,
        radial-gradient(82.79% 104.69% at 20.17% -22.66%, #1D52A0 0%, rgba(29, 82, 160, 0.18) 100%)
        /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
        ,
        radial-gradient(105.11% 170.6% at 105.11% -15.62%, rgba(19, 229, 213, 0.5) 19.66%, rgba(19, 229, 213, 0.06) 71.06%)
        /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
        ,
        radial-gradient(110.23% 82.81% at 50% 17.19%, rgba(255, 255, 255, 0.098) 0%, rgba(255, 255, 255, 0.038) 100%);
}