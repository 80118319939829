.nav-bar-button {
    width: 150px;
    height: 56px;
    padding: 16px 24px;
    border-radius: 16px;
    opacity: 1;
    background:
      radial-gradient(58.24% 134.23% at 33.81% 109.38%, rgba(102, 129, 226, 0.81) 0%, rgba(102, 129, 226, 0) 95.37%),
      radial-gradient(82.79% 104.69% at 20.17% -22.66%, #1D52A0 0%, rgba(29, 82, 160, 0.18) 100%),
      radial-gradient(105.11% 170.6% at 105.11% -15.62%, rgba(19, 229, 213, 0.5) 19.66%, rgba(19, 229, 213, 0.06) 71.06%),
      radial-gradient(110.23% 82.81% at 50% 17.19%, rgba(255, 255, 255, 0.098) 0%, rgba(255, 255, 255, 0.038) 100%),
      linear-gradient(0deg, #82eeff, #82f3ff);
    box-shadow:
      0px 10px 60px 0px rgba(41, 142, 174, 0.8),
      inset 0px 0px 10px 0px rgba(255, 255, 255, 0.5);
  }